// signup.saga.jsx
import { put, takeLatest, call } from 'redux-saga/effects';
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDocs, collection, query, where, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import app from '../../firebaseConfig';

// Function to handle the entire signup process
function* createNewSignup(action) {
  const auth = getAuth(app);
  const db = getFirestore(app);

  try {
    const { email, password, firstName, lastName, onlineName, joinCode } = action.payload;
    const userCredential = yield call(createUserWithEmailAndPassword, auth, email, password);
    const user = userCredential.user;

    // Send email verification
    yield call(sendEmailVerification, user);

    // Generate unique IDs for account-related entities
    const accountID = uuidv4();
    const cupboardID = uuidv4();
    const shoppingCartID = uuidv4();

    // Create a new profile for the user
    const newProfile = {
      account: accountID,
      email: email,
      firstName: firstName,
      id: user.uid,
      isActive: true,
      lastName: lastName,
      lastUpdated: new Date(),
      onlineName: onlineName,
      pictureApproved: false,
      pictureURL: '',
      role: 'owner',
    };

    yield call(setDoc, doc(db, 'profiles', user.uid), newProfile);

    // Create a new account document
    const newAccount = {
      allowedUsers: [user.uid],
      createdOn: new Date(),
      cupboardID: cupboardID,
      id: accountID,
      isActive: true,
      joinCode: joinCode,
      lastUpdated: new Date(),
      lastUpdatedBy: user.uid,
      owner: user.uid,
      shoppingCartID: shoppingCartID,
      subType: 'Free',
    };

    yield call(setDoc, doc(db, 'accounts', accountID), newAccount);

    // Create a new shopping cart document
    const newShopping = {
      id: shoppingCartID,
      accountID: accountID,
      createdOn: new Date(),
      // items: [''],
      lastUpdated: new Date(),
      lastUpdatedBy: user.uid,
    };

    yield call(setDoc, doc(db, 'shoppingCarts', shoppingCartID), newShopping);

    // Create a new cupboard document
    const newCupboard = {
      id: cupboardID,
      accountID: accountID,
      createdOn: new Date(),
      // items: [''],
      lastUpdated: new Date(),
      lastUpdatedBy: user.uid,
    };

    yield call(setDoc, doc(db, 'cupboards', cupboardID), newCupboard);

    // Notify user to check their email
    yield put({ type: 'NOTIFY_EMAIL_VERIFICATION', payload: 'Please check your email to verify your account.' });

    // Dispatch success action
    yield put({ type: 'SIGNUP_SUCCESS' });
  } catch (error) {
    // Dispatch failure action
    yield put({ type: 'SIGNUP_FAILURE', payload: error.message });
    console.error('Error during signup process:', error.message);
  }
}

function* joinNewSignup(action) {
  const auth = getAuth(app);
  const db = getFirestore(app);

  try {
    const { email, password, firstName, lastName, onlineName, joinCode } = action.payload;
    const userCredential = yield call(createUserWithEmailAndPassword, auth, email, password);
    const user = userCredential.user;

    // Send email verification
    yield call(sendEmailVerification, user);

    // Fetch accounts with the given joinCode
    const accountsQuery = yield call(getDocs, query(collection(db, 'accounts'), where('joinCode', '==', joinCode)));

    if (accountsQuery.empty) {
      throw new Error('No account found with this join code');
    }

    const accountDoc = accountsQuery.docs[0];
    const accountData = accountDoc.data();

    // Check if the account has less than 4 allowed users
    if (accountData.allowedUsers.length >= 4) {
      throw new Error('Account is full');
    }

    // Add current user to allowedUsers
    const updatedAllowedUsers = [...accountData.allowedUsers, user.uid];
    yield call(updateDoc, accountDoc.ref, {
      allowedUsers: updatedAllowedUsers,
      lastUpdated: new Date(),
      lastUpdatedBy: user.uid,
    });

    // Create profile
    const newProfile = {
      account: accountDoc.id,
      email: email,
      firstName: firstName,
      id: user.uid,
      isActive: true,
      lastName: lastName,
      lastUpdated: new Date(),
      onlineName: onlineName,
      pictureApproved: false,
      pictureURL: '',
      role: 'user',
    };

    yield call(setDoc, doc(db, 'profiles', user.uid), newProfile);

    // Notify user to check their email
    yield put({ type: 'NOTIFY_EMAIL_VERIFICATION', payload: 'Please check your email to verify your account.' });
    yield put({ type: 'JOIN_SUCCESS' });
  } catch (error) {
    // Dispatch failure action
    yield put({ type: 'JOIN_FAILURE', payload: error.message });
    console.error('Error during join process:', error.message);
  }
}

export default function* signupSaga() {
  yield takeLatest('SIGNUP_REQUEST', createNewSignup);
  yield takeLatest('JOIN_REQUEST', joinNewSignup);
}
