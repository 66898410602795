import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './App.css'; // Use global styles

function Development() {
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const securePasscode = process.env.REACT_APP_PASSCODE;

  const handlePasscodeSubmit = () => {
    if (passcode === securePasscode) {
      setError(null);
      dispatch({ type: 'VALIDATE_PASSCODE', payload: true });
      navigate('/signup');
    } else {
      setError('Invalid passcode.');
    }
  };

  return (
    <div className="center-container">
      <div className="shared-container">
        <h2>Development Access</h2>
        <div className="form-group">
          <label>Passcode:</label>
          <input
            type="password"
            placeholder="Enter passcode"
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            className="custom-input"
          />
        </div>
        <button onClick={handlePasscodeSubmit} className="custom-button">
          Submit
        </button>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
}

export default Development;
