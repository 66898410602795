// account.saga.js

import { put, takeLatest, call } from 'redux-saga/effects';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import app from '../../firebaseConfig';

function* fetchAccount(action) {
  const accountId = action.payload;
  const db = getFirestore(app);
  try {
    const accountDocRef = doc(db, 'accounts', accountId);
    const accountDoc = yield call(getDoc, accountDocRef);
    if (accountDoc.exists()) {
      const accountData = accountDoc.data();
      const account = {
        ...accountData,
        createdOn: accountData.createdOn?.toDate().toISOString() || 'N/A',
        lastUpdated: accountData.lastUpdated?.toDate().toISOString() || 'N/A',
      };
      yield put({ type: 'SET_ACCOUNT', payload: account });
    } else {
      yield put({ type: 'SET_ACCOUNT', payload: null });
    }
  } catch (error) {
    yield put({ type: 'ACCOUNT_FETCH_FAILED', payload: error.message });
  }
}

export default function* accountSaga() {
  yield takeLatest('FETCH_ACCOUNT', fetchAccount);
}
