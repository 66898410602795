// _root.saga.jsx

import { all } from 'redux-saga/effects';
import userSaga from './user.saga';
import profileSaga from './profile.saga';
import accountSaga from './account.saga';
import shoppingSaga from './shopCart.saga';
import cupboardSaga from './cupboard.saga';
import signupSaga from './signup.saga';

export default function* rootSaga() {
  yield all([userSaga(), profileSaga(), accountSaga(), shoppingSaga(), cupboardSaga(), signupSaga()]);
}
