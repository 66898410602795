// shopCart.saga.js

import { put, takeLatest, call } from 'redux-saga/effects';
import { getFirestore, collection, query, where, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import app from '../../firebaseConfig';

function* fetchShopCart(action) {
  const { accountID } = action.payload;
  const db = getFirestore(app);
  try {
    const shopCartQuery = query(collection(db, 'shoppingCarts'), where('accountID', '==', accountID));
    const shopCartQuerySnapshot = yield call(getDocs, shopCartQuery);
    const shopCartDocs = shopCartQuerySnapshot.docs;
    if (shopCartDocs.length > 0) {
      const shopCartData = shopCartDocs[0].data();
      const shopCart = {
        ...shopCartData,
        lastUpdated: shopCartData.lastUpdated.toDate().toISOString(),
      };
      yield put({ type: 'SET_SHOP_CART', payload: shopCart });
    } else {
      yield put({ type: 'SET_SHOP_CART', payload: null });
    }
  } catch (error) {
    yield put({ type: 'SHOP_CART_FETCH_FAILED', payload: error.message });
  }
}

function* addItemToShopCart(action) {
  const { accountID, newItem } = action.payload;
  const db = getFirestore(app);
  try {
    const shopCartQuery = query(collection(db, 'shoppingCarts'), where('accountID', '==', accountID));
    const shopCartQuerySnapshot = yield call(getDocs, shopCartQuery);
    const shopCartDocs = shopCartQuerySnapshot.docs;
    if (shopCartDocs.length > 0) {
      const shopCartDoc = shopCartDocs[0];
      const shopCartData = shopCartDoc.data();
      const updatedItems = [...shopCartData.items, { ...newItem, itemId: uuidv4(), itemDate: new Date() }];
      yield call(updateDoc, shopCartDoc.ref, {
        items: updatedItems,
        lastUpdated: serverTimestamp(),
      });
      yield put({ type: 'FETCH_SHOP_CART', payload: { accountID } });
    }
  } catch (error) {
    yield put({ type: 'SHOP_CART_ADD_ITEM_FAILED', payload: error.message });
  }
}

function* updateItemInShopCart(action) {
  const { accountID, updatedItem } = action.payload;
  const db = getFirestore(app);
  try {
    const shopCartQuery = query(collection(db, 'shoppingCarts'), where('accountID', '==', accountID));
    const shopCartQuerySnapshot = yield call(getDocs, shopCartQuery);
    const shopCartDocs = shopCartQuerySnapshot.docs;
    if (shopCartDocs.length > 0) {
      const shopCartDoc = shopCartDocs[0];
      const shopCartData = shopCartDoc.data();
      const updatedItems = shopCartData.items.map((item) => (item.itemId === updatedItem.itemId ? updatedItem : item));
      yield call(updateDoc, shopCartDoc.ref, {
        items: updatedItems,
        lastUpdated: serverTimestamp(),
      });
      yield put({ type: 'FETCH_SHOP_CART', payload: { accountID } });
    }
  } catch (error) {
    yield put({ type: 'SHOP_CART_UPDATE_ITEM_FAILED', payload: error.message });
  }
}

function* deleteItemFromShopCart(action) {
  const { accountID, itemId } = action.payload;
  const db = getFirestore(app);
  try {
    const shopCartQuery = query(collection(db, 'shoppingCarts'), where('accountID', '==', accountID));
    const shopCartQuerySnapshot = yield call(getDocs, shopCartQuery);
    const shopCartDocs = shopCartQuerySnapshot.docs;
    if (shopCartDocs.length > 0) {
      const shopCartDoc = shopCartDocs[0];
      const shopCartData = shopCartDoc.data();
      const updatedItems = shopCartData.items.filter((item) => item.itemId !== itemId);
      yield call(updateDoc, shopCartDoc.ref, {
        items: updatedItems,
        lastUpdated: serverTimestamp(),
      });
      yield put({ type: 'FETCH_SHOP_CART', payload: { accountID } });
    }
  } catch (error) {
    yield put({ type: 'SHOP_CART_DELETE_ITEM_FAILED', payload: error.message });
  }
}

export default function* shopCartSaga() {
  yield takeLatest('FETCH_SHOP_CART', fetchShopCart);
  yield takeLatest('ADD_ITEM_TO_SHOP_CART', addItemToShopCart);
  yield takeLatest('UPDATE_ITEM_IN_SHOP_CART', updateItemInShopCart);
  yield takeLatest('DELETE_ITEM_FROM_SHOP_CART', deleteItemFromShopCart);
}
