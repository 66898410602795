// signup.reducer.jsx

const initialState = {
  success: false,
  error: null,
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNUP_SUCCESS':
      return { ...state, success: true, error: null };
    case 'SIGNUP_FAILURE':
      return { ...state, success: false, error: action.payload };
    case 'RESET_SIGNUP_STATE':
      return initialState;
    case 'JOIN_SUCCESS':
      return { ...state, success: true, error: null };
    case 'JOIN_FAILURE':
      return { ...state, success: false, error: action.payload };
    case 'RESET_JOIN_STATE':
      return initialState;
    default:
      return state;
  }
};

export default signupReducer;
