// _root.reducer.jsx

import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import profileReducer from './profile.reducer';
import accountReducer from './account.reducer';
import shoppingReducer from './shopCart.reducer';
import cupboardReducer from './cupboard.reducer';
import devModeReducer from './devMode.reducer';
import signupReducer from './signup.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  account: accountReducer,
  shopping: shoppingReducer,
  cupboard: cupboardReducer,
  devMode: devModeReducer,
  signup: signupReducer,
});

export default rootReducer;
