// cupboard.saga.jsx
import { put, takeLatest, call } from 'redux-saga/effects';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import app from '../../firebaseConfig';

function* fetchCupboard(action) {
  const { accountID } = action.payload;
  const db = getFirestore(app);
  try {
    const cupboardQuery = query(collection(db, 'cupboards'), where('accountID', '==', accountID));
    const cupboardQuerySnapshot = yield call(getDocs, cupboardQuery);
    const cupboardDocs = cupboardQuerySnapshot.docs;
    if (cupboardDocs.length > 0) {
      const cupboardData = cupboardDocs[0].data();
      const cupboard = {
        ...cupboardData,
        lastUpdated: cupboardData.lastUpdated.toDate().toISOString(),
      };
      yield put({ type: 'SET_CUPBOARD', payload: cupboard });
    } else {
      yield put({ type: 'SET_CUPBOARD', payload: null });
    }
  } catch (error) {
    yield put({ type: 'CUPBOARD_FETCH_FAILED', payload: error.message });
  }
}

export default function* cupboardSaga() {
  yield takeLatest('FETCH_CUPBOARD', fetchCupboard);
}
