// devMode.reducer.js

const initialState = {
  isValid: false,
};

const devModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VALIDATE_PASSCODE':
      return { ...state, isValid: action.payload };
    default:
      return state;
  }
};

export default devModeReducer;
