// profile.saga.jsx
import { put, takeLatest, call } from 'redux-saga/effects';
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import app from '../../firebaseConfig';

function* fetchProfile(action) {
  const { uid } = action.payload;
  const db = getFirestore(app);
  try {
    const profileDocRef = doc(db, 'profiles', uid);
    const profileDoc = yield call(getDoc, profileDocRef);
    if (profileDoc.exists()) {
      const profileData = profileDoc.data();
      const profile = {
        ...profileData,
        lastUpdated: profileData.lastUpdated.toDate().toISOString(),
      };

      yield put({ type: 'SET_PROFILE', payload: profile });
    } else {
      yield put({ type: 'SET_PROFILE', payload: null });
    }
  } catch (error) {
    yield put({ type: 'PROFILE_FETCH_FAILED', payload: error.message });
  }
}

function* updateProfile(action) {
  const { userId, updatedData } = action.payload;
  const db = getFirestore(app);
  try {
    const updatedProfile = {
      ...updatedData,
      lastUpdated: serverTimestamp(),
    };

    const profileDocRef = doc(db, 'profiles', userId);
    yield call(updateDoc, profileDocRef, updatedProfile);

    yield put({ type: 'UPDATE_PROFILE_SUCCESS', payload: updatedProfile });
  } catch (error) {
    yield put({ type: 'UPDATE_PROFILE_FAILED', payload: error.message });
  }
}

export default function* profileSaga() {
  yield takeLatest('FETCH_PROFILE', fetchProfile);
  yield takeLatest('UPDATE_PROFILE_REQUEST', updateProfile);
}
