// src/PrivateRoute.jsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const isPasscodeValid = useSelector((state) => state.devMode.isValid);
  return isPasscodeValid ? children : <Navigate to="/development" />;
};

export default PrivateRoute;
