// Home.jsx

import React, { useState, useEffect } from 'react';
import './App.css';

function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const images = ['images/rw001.webp', 'images/rw002.webp', 'images/rw003.webp', 'images/rw004.webp'];

  const imageTexts = [
    'Effortlessly manage your profile with Kitchen Queue. Easily create, update, and customize your account settings to ensure a seamless experience across all your devices.',
    'Stay organized with Kitchen Queue’s real-time shopping list management. Add, edit, and track items as they move from your list to your cart, synchronized instantly across all devices.',
    'Keep track of your kitchen inventory like never before. Maintain an accurate inventory of items in your cupboards, refrigerators, and freezers with Kitchen Queue, automatically adjusting quantities as items are added or used.',
    'Experience seamless collaboration with Kitchen Queue. Each account supports up to four users, allowing household members to easily view, add, and update items in shopping lists, carts, and cupboards.',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeIn(true);
      }, 1000);
    }, 11000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index + 1}`}
          className="carousel-image"
          style={{ opacity: index === currentImageIndex ? 1 : 0 }}
        />
      ))}
      <div className={`carousel-text ${fadeIn ? 'show' : ''}`}>
        <h2 className="ft-7">Kitchen Queue</h2>
        <h3 className="ft-6">(Coming Soon)</h3>
        <p className="ft-5">{imageTexts[currentImageIndex]}</p>
      </div>
    </div>
  );
}

export default Home;
