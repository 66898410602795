// user.saga.js

import { put, takeLatest, call } from 'redux-saga/effects';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

import app from '../../firebaseConfig';

function* loginUser(action) {
  const auth = getAuth(app);
  try {
    const { email, password } = action.payload;
    const userCredential = yield call(signInWithEmailAndPassword, auth, email, password);

    const user = userCredential.user;

    yield put({ type: 'SET_USER', payload: user });
    yield put({
      type: 'FETCH_PROFILE',
      payload: { uid: user.uid, role: user.role },
    });
  } catch (error) {
    yield put({ type: 'LOGIN_FAILED', payload: error.message });
  }
}

function* logoutUser() {
  const auth = getAuth(app);
  try {
    yield call(signOut, auth);
    yield put({ type: 'UNSET_USER' });
  } catch (error) {
    yield put({ type: 'LOGOUT_FAILED', payload: error.message });
  }
}

export default function* userSaga() {
  yield takeLatest('LOGIN_REQUEST', loginUser);
  yield takeLatest('LOGOUT', logoutUser);
}
