import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import { useNavigate } from 'react-router-dom';
import { Icons } from './components/IconListRouter';

import { InputMask } from 'primereact/inputmask';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [onlineName, setOnlineName] = useState('');
  const [formattedJoinCode, setFormattedJoinCode] = useState('');
  const [isCreatingAccount, setIsCreatingAccount] = useState(null);
  const [formComplete, setFormComplete] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isValidEmail = useCallback((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email), []);

  const isValidPassword = useCallback(
    (password) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password),
    []
  );

  const doPasswordsMatch = useCallback(() => password === confirmPassword, [password, confirmPassword]);

  const validateForm = useCallback(() => {
    if (!firstName || !lastName || !onlineName) {
      setError('Please enter all fields.');
      return false;
    }
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return false;
    }
    if (!isValidPassword(password)) {
      setError('Please follow the password checklist below.');
      return false;
    }
    if (!doPasswordsMatch()) {
      setError('Passwords do not match.');
      return false;
    }
    setError(null);
    return true;
  }, [firstName, lastName, onlineName, isValidEmail, email, isValidPassword, password, doPasswordsMatch]);

  const handleCreateAccount = () => {
    if (validateForm()) {
      const generatedJoinCode = Array.from({ length: 25 }, () => Math.floor(Math.random() * 10).toString()).join('');
      dispatch({
        type: 'SIGNUP_REQUEST',
        payload: { email, password, firstName, lastName, onlineName, joinCode: generatedJoinCode },
      });
      setFormComplete(true);
    }
  };

  const handleJoinAccount = () => {
    const cleanJoinCode = formattedJoinCode.replace(/-/g, '');
    if (cleanJoinCode.length !== 25) {
      setError('Please enter a complete join code.');
      return;
    }
    if (validateForm()) {
      dispatch({
        type: 'JOIN_REQUEST',
        payload: { email, password, firstName, lastName, onlineName, joinCode: cleanJoinCode },
      });
      setFormComplete(true);
    }
  };

  useEffect(() => {
    if (formComplete) {
      const timer = setTimeout(() => {
        navigate('/');
        setFormComplete(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [formComplete, navigate]);

  const passwordValidation = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
    special: /[@$!%*?&]/.test(password),
  };

  if (formComplete) {
    return (
      <div className="center-container">
        <div className="shared-container">
          <div className="account-created-container">
            <h2>Account Created!</h2>
            <p>Please check your email to verify your account.</p>
          </div>
        </div>
      </div>
    );
  }

  const CheckListWrap = ({ children }) => (
    <div className="password-checklist">
      <ul>{children}</ul>
    </div>
  );

  const CheckListItem = ({ value, text }) => (
    <li>
      {value ? <Icons.CheckFilledCircle color="44B3B3" /> : <Icons.EmptyCircle color="#c4c4c4" />} {text}
    </li>
  );

  return (
    <div className="center-container">
      <div className="shared-container">
        <center>
          <h2>Account Setup</h2>
        </center>
        {isCreatingAccount === null ? (
          <>
            <button className="custom-button" onClick={() => setIsCreatingAccount(true)}>
              Create Account
            </button>
            <button className="custom-button" onClick={() => setIsCreatingAccount(false)}>
              Join Account
            </button>
          </>
        ) : (
          <>
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
                className="custom-input"
              />
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
                className="custom-input"
              />
            </div>
            <div className="form-group">
              <label>Online Name:</label>
              <input
                type="text"
                value={onlineName}
                onChange={(e) => setOnlineName(e.target.value)}
                placeholder="Enter your online name"
                className="custom-input"
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="custom-input"
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                className="custom-input"
              />
            </div>
            <div className="form-group">
              <label>Confirm Password:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
                className="custom-input"
              />
            </div>
            {isCreatingAccount === false && (
              <div className="form-group">
                <label htmlFor="serial" className="font-bold block mb-2">
                  Join Code
                </label>
                <InputMask
                  style={{ width: '100%' }}
                  className="custom-input"
                  id="serial"
                  mask="*****-*****-*****-*****-*****"
                  placeholder="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
                  value={formattedJoinCode}
                  onChange={(e) => setFormattedJoinCode(e.target.value)}
                />
              </div>
            )}
            {error && <p className="error">{error}</p>}
            <CheckListWrap>
              <CheckListItem value={passwordValidation.length} text="At least 8 characters" />
              <CheckListItem value={passwordValidation.uppercase} text="An uppercase letter" />
              <CheckListItem value={passwordValidation.lowercase} text="A lowercase letter" />
              <CheckListItem value={passwordValidation.number} text="A number" />
              <CheckListItem value={passwordValidation.special} text="A special character (@$!%*?&)" />
              <CheckListItem value={password && confirmPassword && doPasswordsMatch()} text="Passwords match" />
            </CheckListWrap>
            <button className="custom-button" onClick={isCreatingAccount ? handleCreateAccount : handleJoinAccount}>
              {isCreatingAccount ? 'Confirm New Account' : 'Confirm Join Account'}
            </button>
            <button className="link-button" onClick={() => setIsCreatingAccount(!isCreatingAccount)}>
              {isCreatingAccount ? 'Switch to Join Account' : 'Switch to Create Account'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Signup;
